import * as React from "react";
import Seo from "../components/Seo";
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import scissors from "../images/icons/scissors-solid.svg";
import location from "../images/icons/location-dot-solid.svg";
import paintbrush from "../images/icons/paintbrush-solid.svg";

const items = [
  {
    icon: scissors,
    alt: "Scissors Icon",
    heading: "Area of service",
    description:
      "Serving Amador County, Calaveras, El Dorado Hills, and the greater Sacramento area",
  },
  {
    icon: location,
    alt: "Location Icon",
    heading: "Color Technology",
    description:
      "Specializing in color treatments such as balayages, vivid color, highlights, restorative color, gloss, and tinsel",
  },
  {
    icon: paintbrush,
    alt: "Paintbrush Icon",
    heading: "Haircuts & Treatments",
    description:
      "Offering the latest trends in haircuts for men, women, and children, as well as hair treatments to restore damaged hair",
  },
];

const IndexPage = () => (
  <Layout>
    <Hero>
      <a
        href="https://fionnahouse.glossgenius.com/services"
        target="_blank"
        rel="noopener noreferrer"
        className="py-2 sm:py-8 tracking-widest text-xl sm:text-4xl font-thin px-4 sm:px-10 sm:mt-0 mt-36 outline outline-gray-200"
      >
        Book Now
      </a>
      <div className="grid md:grid-cols-3 grid-cols-1 px-8 max-w-6xl gap-16 sm:pt-32 pt-12">
        {items.map(({ icon, heading, description, alt }) => (
          <div
            key={alt}
            className="flex text-center items-center mt-8 flex-col"
          >
            <img className="h-12 w-12 pink-filter" src={icon} alt={alt} />
            <h5 className="py-4 text-2xl small-caps">{heading}</h5>
            <p className="font-light text-xl text-center">{description}</p>
          </div>
        ))}
      </div>
    </Hero>
  </Layout>
);

export const Head = () => <Seo title="Home" />;

export default IndexPage;
